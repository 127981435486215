var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"news"}},[(_vm.fristNewsData.length)?_c('TopNews',{attrs:{"mainBg":("https://kirinapi.howdesign.com.tw/" + (_vm.fristNewsData[0].pic.path)),"pageName":'最新消息',"titleWord":(function () {
      return [{
        word: 'News',
        ifBold: true,
      },
      {
        word: '',
        ifBold: false,
      }]
    })(),"bottomBgColor":'#f8f8f8',"route":{
      name: 'NewsInfo',
      params: { id: _vm.fristNewsData[0]._id },
    },"date":_vm.fristNewsData[0].publish_date,"newsTitle":_vm.fristNewsData[0].title,"newsInfo":_vm.fristNewsData[0].content}}):_vm._e(),_c('div',{ref:"news_list_outer",staticClass:"news_list_outer main_padding"},[_c('div',{staticClass:"w1400"},[_c('h5',{staticClass:"sub_title txt-bold txt-center"},[_vm._v(" 全部消息 ")]),(_vm.newsData.length > 0)?_c('div',{staticClass:"news_list"},_vm._l((_vm.newsData),function(item,i){return _c('router-link',{key:("newsItem_" + i + "_" + (item._id)),staticClass:"news_item",attrs:{"to":{
              name: 'NewsInfo',
              params: { id: item._id },
            }}},[_c('div',{staticClass:"pic"},[_c('figure',{style:({
                    backgroundImage: ("url('https://kirinapi.howdesign.com.tw/" + (item.pic.path) + "')")
                  })})]),_c('div',{staticClass:"date"},[_c('h2',{staticClass:"h1 txt-theme"},[_vm._v(_vm._s(_vm.conversionDate(item.publish_date)[2]))]),_c('small',[_vm._v(" "+_vm._s(_vm.conversionDate(item.publish_date)[0])+"年 "+_vm._s(_vm.conversionDate(item.publish_date)[1])+"月 "),_c('span',[_vm._v(" "+_vm._s(_vm.conversionDate(item.publish_date)[2])+"號 ")])])]),_c('div',{staticClass:"text"},[_c('div',{staticClass:"text_in"},[_c('h4',{staticClass:"txt-bold"},[_vm._v(_vm._s(item.title))]),_c('p',{domProps:{"innerHTML":_vm._s(item.content)}})]),_c('div',{staticClass:"arrow-circle"})])])}),1):_c('div',{staticClass:"main_padding-t"},[_c('h4',{staticClass:"txt-gray txt-center"},[_vm._v(" 沒有更多最新消息 ")])]),_c('div',{staticClass:"pagination txt-right"},[(_vm.nowPage > 1)?_c('router-link',{staticClass:"to_prev",attrs:{"to":{
            name: 'NewsList',
            params: { page: _vm.nowPage - 1 },
          }}},[_c('img',{attrs:{"src":require("@/assets/img/public/arrow-g.svg"),"alt":""}})]):_vm._e(),_vm._l((_vm.pagination),function(item,i){return [(typeof(item) === 'number')?_c('router-link',{key:("page_" + i + "_" + _vm.pagination),attrs:{"to":{
              name: 'NewsList',
              params: { page: item },
            }}},[_vm._v(" "+_vm._s(item)+" ")]):_c('span',{key:("page_" + i + "_" + _vm.pagination),staticClass:"txt-gray"},[_vm._v(" ... ")])]}),(_vm.nowPage <= _vm.totalPage - 1)?_c('router-link',{staticClass:"to_next",attrs:{"to":{
            name: 'NewsList',
            params: { page: _vm.nowPage + 1 },
          }}},[_c('img',{attrs:{"src":require("@/assets/img/public/arrow-g.svg"),"alt":""}})]):_vm._e()],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }