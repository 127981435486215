<template>
  <div id="top_news">
    <div
      class="colorbg"
      :style="{backgroundColor: bottomBgColor}">
    </div>

    <div class="inner w1400">

      <div class="page_title">
        <small class="page_bread">
          首頁 -&nbsp;
          <span class="txt-gray">
            {{pageName}}
          </span>
        </small>

        <h2 class="txt-right txt-theme txt-up">
          <span :class="{'txt-bold' : titleWord[0].ifBold}">
            {{titleWord[0].word}}
          </span>
          <span :class="{'txt-bold' : titleWord[1].ifBold}">
            &nbsp;{{titleWord[1].word}}
          </span>
        </h2>
      </div>

      <div class="block">
        <router-link :to="route" class="news_item">
          <div class="left txt-white">
            <p>
              {{new Date(date).getFullYear()}}年
              {{new Date(date).getMonth()+1}}月
              {{new Date(date).getDate()}}日
            </p>
            <div class="text">
              <div class="text_in">
                <h4 class="txt-bold">{{newsTitle}}</h4>
                <p
                  v-html="newsInfo">
                </p>
              </div>
              <div class="arrow-circle white"></div>
            </div>
          </div>
          <figure
            :style="{
              backgroundImage: `url(${mainBg})`
            }">
          </figure>
        </router-link>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'TopNews',
  props: {
    pageName: { type: String },
    mainBg: { type: String },
    titleWord: {
      type: Array,
      default: () => [
        {
          word: '',
          ifBold: false,
        },
        {
          word: '',
          ifBold: true,
        },
      ],
    },
    bottomBgColor: {
      type: String,
      default: '#f8f8f8',
    },
    route: { type: Object },
    date: { type: String },
    newsTitle: {
      type: String,
      default: '預設文章標題',
    },
    newsInfo: {
      type: String,
      default: '預設文章內文',
    },
  },
  components: {
  },
};
</script>
