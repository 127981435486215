<template>
  <section id="news">
    <TopNews
      v-if="fristNewsData.length"
      :mainBg="`https://kirinapi.howdesign.com.tw/${fristNewsData[0].pic.path}`"
      :pageName="'最新消息'"
      :titleWord="(() => {
        return [{
          word: 'News',
          ifBold: true,
        },
        {
          word: '',
          ifBold: false,
        }]
      })()"
      :bottomBgColor="'#f8f8f8'"
      :route="{
        name: 'NewsInfo',
        params: { id: fristNewsData[0]._id },
      }"
      :date="fristNewsData[0].publish_date"
      :newsTitle="fristNewsData[0].title"
      :newsInfo="fristNewsData[0].content"/>

    <div class="news_list_outer main_padding" ref="news_list_outer">
      <div class="w1400">
        <h5 class="sub_title txt-bold txt-center">
          全部消息
        </h5>

        <div
          class="news_list"
          v-if="newsData.length > 0">
            <router-link
              v-for="(item, i) in newsData"
              :key="`newsItem_${i}_${item._id}`"
              :to="{
                name: 'NewsInfo',
                params: { id: item._id },
              }"
              class="news_item">
                <div class="pic">
                  <figure
                    :style="{
                      backgroundImage: `url('https://kirinapi.howdesign.com.tw/${item.pic.path}')`
                    }">
                  </figure>
                </div>
                <div class="date">
                  <h2 class="h1 txt-theme">{{conversionDate(item.publish_date)[2]}}</h2>
                  <small>
                    {{conversionDate(item.publish_date)[0]}}年
                    {{conversionDate(item.publish_date)[1]}}月
                    <span>
                      {{conversionDate(item.publish_date)[2]}}號
                    </span>
                  </small>
                </div>
                <div class="text">
                  <div class="text_in">
                    <h4 class="txt-bold">{{item.title}}</h4>
                    <p
                      v-html="item.content">
                    </p>
                  </div>
                  <div class="arrow-circle"></div>
                </div>
            </router-link>
        </div>
        <div
          class="main_padding-t"
          v-else>
            <h4 class="txt-gray txt-center">
              沒有更多最新消息
            </h4>
        </div>

        <div class="pagination txt-right">
          <router-link
            v-if="nowPage > 1"
            :to="{
              name: 'NewsList',
              params: { page: nowPage - 1 },
            }"
            class="to_prev">
              <img src="@/assets/img/public/arrow-g.svg" alt="">
          </router-link>

          <template v-for="(item, i) in pagination">
            <router-link
              v-if="typeof(item) === 'number'"
              :key="`page_${i}_${pagination}`"
              :to="{
                name: 'NewsList',
                params: { page: item },
              }">
                {{item}}
            </router-link>
            <span
              v-else
              :key="`page_${i}_${pagination}`"
              class="txt-gray">
                ...
            </span>
          </template>
          <router-link
            v-if="nowPage <= totalPage - 1"
            :to="{
              name: 'NewsList',
              params: { page: nowPage + 1 },
            }"
            class="to_next">
              <img src="@/assets/img/public/arrow-g.svg" alt="">
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TopNews from '@/components/public/TopNews.vue';
import requestApi from '@/lib/http/index';
import titleMixin from '@/mixins/titleMixin';

export default {
  name: 'News',
  mixins: [titleMixin],
  components: {
    TopNews,
  },
  props: {
    page: {
      type: [Number, String],
      default: '1',
    },
  },
  data() {
    return {
      newsMaxCount: 6,
      newsTotal: 0,
      fristNewsData: [],
      newsData: [],
    };
  },
  watch: {
    $route(to, form) {
      console.log(to, form);
      if (
        to.params.page < 1
        || to.params.page > this.totalPage
      ) {
        this.$router.push(
          {
            name: 'NewsList',
            params: { page: 1 },
          },
        );
      } else {
        this.getData(6, 1 + (this.nowPage - 1) * 6);

        if (this.nowPage > 1) {
          window.scrollTo({
            top: this.$refs.news_list_outer.offsetTop,
            behavior: 'instant',
          });
        } else {
          window.scrollTo({
            top: 0,
            behavior: 'instant',
          });
        }
      }
    },
  },
  computed: {
    nowPage() {
      return Number(this.page);
    },
    totalPage() {
      return Math.ceil(this.newsTotal / this.newsMaxCount);
    },
    pagination() {
      const showPages = [];

      if (this.nowPage >= 2) {
        showPages.push(1);
      }
      if (this.nowPage >= 3) {
        showPages.push('...');
      }

      for (let i = 0; i < 3; i += 1) {
        if (this.nowPage + i <= this.totalPage) {
          showPages.push(this.nowPage + i);
        }
      }

      if (this.nowPage < this.totalPage - 3) {
        showPages.push('...');
      }
      if (this.nowPage < this.totalPage - 2) {
        showPages.push(this.totalPage);
      }

      return showPages;
    },
  },
  methods: {
    async getFirstNews() {
      const result = await requestApi(
        'news.index',
        {
          limit: 1,
          filter: { status: 'Y' },
          sort: { _created: -1 },
        },
      );
      console.log(result);

      this.fristNewsData = [...result.entries];
    },
    async getData(limit, skip) {
      const result = await requestApi(
        'news.index',
        {
          limit,
          skip,
          filter: { status: 'Y' },
          sort: { _created: -1 },
        },
      );
      console.log(result);

      this.newsData = [...result.entries];
      this.newsTotal = result.total;

      if (result.entries.length === 0 && Number(this.$route.params.page) !== 1) {
        this.$router.push({
          name: 'NewsList',
          params: { page: 1 },
        });
      }
    },
    conversionDate(date) {
      const d = date.split('-');
      return d;
    },
  },
  created() {
    this.getFirstNews();
    this.getData(6, 1 + (this.nowPage - 1) * 6);

    if (
      // eslint-disable-next-line no-restricted-globals
      isNaN(this.nowPage)
      || this.$route.params.page < 1
      // || this.$route.params.page > this.totalPage
    ) {
      this.$router.push(
        {
          name: 'NewsList',
          params: { page: 1 },
        },
      );
    }
  },
  mounted() {
    // console.log(this.$route);
    // console.log(this.$props.page);
    // console.log('nowPage', this.nowPage);
    // console.log('totalPage', this.totalPage);
    // console.log('showPages', this.pagination);
  },
};
</script>

<style
  scoped
  src="@/assets/scss/news.scss"
  lang="scss">
</style>
